/**
 * Variables y elementos de uso global en la plataforma
 */

export default {

    //----------Elementos generales----------------------
    apiKey: null,
    geocoding_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
    quitarPuntosDeInteres: [
        {
            featureType: "poi",
            stylers: [{visibility: "off"}]
        }
    ],
    centroMapa: { lat: 20.550588056146232, lng: -102.51073885775286 },
    puntoBase: { lat: 20.550588056146232, lng: -102.51073885775286 },
    tipoMapa: "satellite",

    formatoFecha: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'},
    formatoFechaHora: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: "2-digit", minute: "2-digit", hourCycle: "h12" },

    api: `${process.env.VUE_APP_URL_BACK}/api/`,
    direccion_servidor: `${process.env.VUE_APP_URL_FRONT}/#/informacion/`,
    
    usuario: {
        usuario: null,
        nombre: null,
        tipo: null,
        id: null,
        id_main: null,
        id_empresa: null,
        nombre_empresa: null,
    },

    regiones: [
        "Jalisco (Valles)",
        "Jalisco (Ciénega)",
        "Jalisco (Altos)",
        "Jalisco (Centro)",
        "Jalisco (Lagunas)",
        "Jalisco (Costa-Sierra Madre Occidental)",
        "Jalisco (Costa Sur)",
        "Jalisco (Sierra de Amula)",
        "Jalisco (Sur)",
        "Jalisco (Sureste)",
        "Jalisco (Norte)",
        "Michoacán",
        "Guanajuato",
        "Nayarit",
        "Tamaulipas"
    ],

    //---------Información de tablas--------------
    datosClientes: [],
    datosStaff: [],

    //--------Información de Formas---------------
    datosFormaCliente: {},
    datosFormaStaff: {},
    datosPefil: {},
    datosFormaMarcas: {},
    presentacionesFormaMarcas:[],
    datosFormaFabricas: {},
    datosFormaProcesos: {},
    datosFormaPredios: {},
    datosFormaLotes:{},
    datosFormaEmbotellados:{},

    //---------Encabezados compartidos para tablas------------

    //---------Elementos de comboBoxes------------------------
    listaNOMs: [{text: "", value: ""}],
    lista_marcas: [{text: "", value: ""}],
    lista_presentaciones: [{text: "", value: ""}],
    lista_lotes: [{text: "", value: ""}],
    lista_marcas_ex: []
}
