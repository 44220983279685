/**
 * Ejecución principal de la plataforma
 * 
 * @author Arturo J Lopez Fausto
 * @version 1.0
 */

import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from '@/router';
import * as VueGoogleMaps from 'vue2-google-maps';
import VueCryptojs from 'vue-cryptojs'

//-------------------Inicializacion de componentes para arrancar la interfaz grafica------------------------------
Vue.use(VueCryptojs);

Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API_KEY,
        libraries: 'places'
    }
});

function usuarioAutentificado(){
    if(sessionStorage.getItem("usuario") != null || sessionStorage.getItem("usuario") != undefined){
        store.commit('setUsuarios', {
            nombre: sessionStorage.getItem("nombre"),
            tipo: sessionStorage.getItem("tipo"), 
            usuario: sessionStorage.getItem("usuario"), 
            id: sessionStorage.getItem("id"),
            id_main: sessionStorage.getItem("id_main"),
            id_empresa: sessionStorage.getItem("id_empresa"),
            nombre_empresa: sessionStorage.getItem("nombre_empresa"),
        });
        store.commit("guardarApiKey", sessionStorage.getItem('token_api'));
        return true;
    }else{
        store.commit('setUsuarios', {
            nombre: null,
            tipo: null,
            usuario: null,
            id: null,
            id_main: null,
            id_empresa: null,
            nombre_empresa: null,
        });
        sessionStorage.setItem('token_api', null);
        return false;
    }
}

/**
 * Verificar credenciales de usuario para acceder a las vistas seleccionadas en la ruta del browser
 */
router.beforeEach((to, from, next) => {
    const isAuthenticated = usuarioAutentificado(); 

    if (to.matched.some((route) => route.meta.requiresAuth)) {
        
        // La ruta requiere autenticación
        if (isAuthenticated) {
        // El usuario está autenticado, permitir la navegación
            next();
        } else {
            // El usuario no está autenticado, redirigir a la página de inicio de sesión
            next('/login');
        }
    
    //En caso de que la ruta no requiera autentificacion
    } else {
        
        if(isAuthenticated && to.path === '/login'){
            if(store.state.usuario.tipo === 'SuperAdmin'){
                next('/admin_clientes');
            }else{
                next('/dashboard');
            }
        }else{
            next();
        }
    }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')