import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

//Definicion de los colores principales a utilizar
export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2C4E78',
                secondary: '#8FBC7B',
                accent: '#64B1B8'
            },
        },
    },
});
