<!--Vista Principal. Manejador del router-->
<template>
    <v-app>
        <BarraUsuarios></BarraUsuarios>
        <v-fade-transition mode="out-in">
            <router-view />
        </v-fade-transition>
    </v-app>
</template>
<script>
import BarraUsuarios from '@/components/BarraUsuarios.vue';

export default{
    components: {
        BarraUsuarios,
    },

    mounted() {
        
    }
}

</script>
<style>
@import './assets/css/satoshi.css';
@import './assets/css/generales.css';

.v-application {
    font-family: "Satoshi-Regular", Helvetica, Arial !important;
    /*.title { // To pin point specific classes of some components
       font-family: $title-font, sans-serif !important;
    }*/
 }
</style>