// https://vuex.vuejs.org/en/mutations.html

export default {
  setUsuarios: (state, valores) => {
    
    state.usuario.nombre = valores.nombre;
    state.usuario.tipo = valores.tipo;
    state.usuario.usuario = valores.usuario;
    state.usuario.id = valores.id;
    state.usuario.id_main = valores.id_main;
    state.usuario.id_empresa = valores.id_empresa;
    state.usuario.nombre_empresa = valores.nombre_empresa;
    state.usuario.logo_empresa = valores.logo_empresa;
  },

  guardarApiKey: (state, nuevoValor) => {
    state.apiKey = nuevoValor;
  },

  setCentroMapa: (state, nuevosValores) => {
    state.centroMapa = nuevosValores;
  },

  //---------------ELEMENTOS FORMA CLIENTE---------------------------
  setDatosClientes: (state, nuevosValores) => {
    state.datosClientes = [...nuevosValores];
  },

  setDatosFormaCliente: (state, valor) => {
    state.datosFormaCliente = {...valor};
  },

  setDatosFormaCampoCliente: (state, valor) => {
    state.datosFormaCliente[valor[0]] = valor[1];
  },

  //------------ELEMENTOS PERFIL---------------------------
  setDatosPerfil: (state, valor) => {
    state.datosPerfil = {...valor};
  },

  setDatosCampoPerfil: (state, valor) => {
    state.datosPerfil[valor[0]] = valor[1];
  },

  setListaNOMs: (state, valor) => {
    state.listaNOMs = [...valor];
  },

  //MENU LATERAL
  setLogo: (state, valor) => {
    state.usuario.logo_empresa = valor;
  },

   //---------------ELEMENTOS FORMA STAFF---------------------------
   setDatosStaff: (state, nuevosValores) => {
    state.datosStaff = [...nuevosValores];
  },

  setDatosFormaStaff: (state, valor) => {
    state.datosFormaStaff = {...valor};
  },

  setDatosFormaCampoStaff: (state, valor) => {
    state.datosFormaStaff[valor[0]] = valor[1];
  },

  //---------------ELEMENTOS FORMA MARCAS---------------------------
  setDatosFormaMarcas: (state, valor) => {
    state.datosFormaMarcas = {...valor};
  },

  setPresentacionFormaMarcas: (state, valor) => {
    state.presentacionesFormaMarcas = [...valor];
  },

  setDatosFormaCampoMarcas: (state, valor) => {
    state.datosFormaMarcas[valor[0]] = valor[1];
  },

  addPresentacionLista: (state, valor) => {
    state.presentacionesFormaMarcas.push(valor);
  },
  
  removePresentacionLista: (state, indice) => {
    state.presentacionesFormaMarcas.splice(indice, 1);
  },

  setListaMarcasEx: (state, value) => {
    state.lista_marcas_ex = [...value];
  },

  //---------------ELEMENTOS FORMA FABRICAS---------------------------
  setDatosFormaFabricas: (state, valor) => {
    state.datosFormaFabricas = {...valor};
  },

  setDatosFormaCampoFabricas: (state, valor) => {
    state.datosFormaFabricas[valor[0]] = valor[1];
  },

  setDatosFormaProcesos: (state, valor) => {
    state.datosFormaProcesos = {...valor};
  },

  setDatosFormaCampoProcesos: (state, valor) => {
    state.datosFormaProcesos[valor[0]] = valor[1];
  },

  addPProcesoLista: (state, valor) => {
    state.datosFormaFabricas.procesos.push(valor);
  },
  
  removeProcesoLista: (state, indice) => {
    state.datosFormaFabricas.procesos.splice(indice, 1);
  },

  //-----------------ELEMENTOS FORMA PREDIOS----------------------------
   setDatosFormaPredios: (state, valor) => {
    state.datosFormaPredios = {...valor};
  },
  setDatosFormaCampoPredios: (state, valor) => {
    state.datosFormaPredios[valor[0]] = valor[1];
  },

  //-----------------ELEMENTOS FORMA LOTES---------------------------------
  setDatosFormaLotes: (state, valor) => {
    state.datosFormaLotes = {...valor};
  },
  setDatosFormaCampoLotes: (state, valor) => {
    state.datosFormaLotes[valor[0]] = valor[1];
  },

  //------------------ELEMENTOS PARA EMBOTELLADOS-------------------------------
  setDatosFormaEmbotellados: (state, valor) => {
    state.datosFormaEmbotellados = {...valor};
  },
  setDatosFormaCampoEmbotellados: (state, valor) => {
    state.datosFormaEmbotellados[valor[0]] = valor[1];
  },
  setListaMarcas: (state, valor) => {
    state.lista_marcas = [...valor];
  },
  setListaPresentaciones: (state, valor) => {
    state.lista_presentaciones = [...valor];
  },
  setListaLotes: (state, valor) => {
    state.lista_lotes = [...valor];
  },
}