<!--Componente para dibujar la barra superior para la vista de usuarios-->
<template>
    <nav>
        <v-app-bar 
            v-if="this.$store.state.usuario.usuario != null"
            app 
            flat
            dark 
            color="primary">
            <v-container fluid>
                <v-row class="justify center">
                    <v-col cols="4" class="d-flex justify-start">
                        <span 
                            v-if="$store.state.usuario.tipo == 'SuperAdmin'"
                            class="tituloBarra white--text"
                            style = "cursor: pointer"
                            @click="$router.replace({path: '/admin_clientes'});">
                            BlueID
                        </span>

                        <span 
                            v-else
                            class="tituloBarra white--text"
                            style = "cursor: pointer"
                            @click="$router.replace({path: '/dashboard'});">
                            BlueID
                        </span>
                    </v-col>

                    <v-spacer></v-spacer>

                    <!--Nombre de usuario en la parte superior derecha-->
                    <v-col cols="4" class="d-flex justify-end">
                        <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    tile
                                    :style = "{ fontFamily: 'Satoshi-Regular', fontSize: '14px' }"
                                    plain
                                    v-bind="attrs"
                                    v-on="on">
                                    <v-icon large left>
                                        mdi-account-circle
                                    </v-icon>
                                    <span class="ml-3">{{ $store.state.usuario.nombre }}</span>
                                </v-btn>
                            </template>
                    
                            <!--Menu de opciones al presionar el nombre del usuario-->
                            <v-list dense>
                                <v-list-item v-if="$store.state.usuario.tipo == 'Main'" @click="$router.replace({path: '/perfil'});">
                                    <v-list-item-icon>
                                        <v-icon>mdi-account-search</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Perfil</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item v-if="$store.state.usuario.tipo == 'Main'" @click="$router.replace({path: '/staff'});">
                                    <v-list-item-icon>
                                        <v-icon>mdi-account-group</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Staff</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                
                                <v-list-item @click="cerrarSesion">
                                    <v-list-item-icon>
                                        <v-icon>mdi-logout</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Cerrar Sesión</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>
                </v-row>
            </v-container>
        </v-app-bar>
    </nav>
</template>

<script>
export default {
    
    data: () => ({
        drawer: false,
        titulo: "BlueID",
        img_avatar: './favicon.png',
    }),

    props: {
        tipoBarra: String,
    },

    computed: {
        
    },

    methods: {
        /*dashboard(){
            this.$router.push({path: 'dashboard'});
        },
        general(){
            this.$router.push({path: 'usuarios'});
        },
        configurarPerfil(){
            this.$router.push({path: 'perfil'});
        },*/
        cerrarSesion(){
            //this.$store.commit('setSesionActiva', false);
            sessionStorage.clear();
            this.$store.commit("guardarApiKey", null);


            this.$store.commit('setUsuarios', { 
                    usuario: {
                        usuario: null,
                        nombre: null,
                        id: null,
                        tipo: null,
                        id_main: null,
                        id_empresa: null,
                        nombre_empresa: null
                }
            });
            this.$router.replace({path: '/login'});
        }
    }
}
</script>

<style lang="scss">
    @import '../assets/css/satoshi.css';

    #app-drawer {
    .v-list__tile {
    border-radius: 4px;

    &--buy {
        margin-top: auto;
        margin-bottom: 17px;
    }
    }

    .v-image__image--contain {
    top: 9px;
    height: 60%;
    }

    .search-input {
    margin-bottom: 30px !important;
    padding-left: 15px;
    padding-right: 15px;
    }

    .nombre-usuario {
        font-family: "Satoshi-Bold", Helvetica, Arial;
        font-size: 16px;
    }

    .titulo_login{
        font-family: "Satoshi-LightItalic", Helvetica, Arial;
        font-size: 24px;
    }
  }
</style>