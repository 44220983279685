/**
 * Definicion general de rutas de la aplicación
 */
export default [
    {
        path: '/login',
        name: 'Información de productos. Iniciar sesión',
        view: 'Login',
        meta: { requiresAuth: false },
    },
    {
        path: '/admin_clientes',
        name: 'Administración de clientes',
        view: 'Clientes',
        meta: { requiresAuth: true },
    },
    {
        path: '/dashboard',
        name: 'Creación y visualización de informacion',
        view: 'Dashboard',
        meta: { requiresAuth: true },
    },
    {
        path: '/perfil',
        name: 'Perfil de usuario',
        view: 'Perfil',
        meta: { requiresAuth: true },
    },
    {
        path: '/staff',
        name: 'Administración del staff',
        view: 'Staff',
        meta: { requiresAuth: true },
    },
    {
        path: '/marcas',
        name: 'Marcas de tequilas',
        view: 'Marcas',
        meta: { requiresAuth: true },
    },
    {
        path: '/fabricas',
        name: 'Fabricas',
        view: 'Fabricas',
        meta: { requiresAuth: true },
    },
    {
        path: '/predios',
        name: 'Predios',
        view: 'Predios',
        meta: { requiresAuth: true },
    },
    {
        path: '/lotes',
        name: 'Lotes',
        view: 'Lotes',
        meta: { requiresAuth: true },
    },
    {
        path: '/informacion/:id_main/:num_registro',
        name: 'Información de la botella',
        view: 'Informacion',
        meta: { requiresAuth: false },
    }
]